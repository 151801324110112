<template>
  <div class="marketing">
    <div class="top">
      <div class="top-bg"></div>
      <div class="my-swipe-box">
        <van-swipe autoplay="3000" class="top-swipe" @change="onChange">
          <van-swipe-item
            v-for="(image, index) in images"
            :key="index"
            @click="toUrl(image)"
          >
            <van-image
              class="image-heard"
              fit="cover"
              lazy-load
              :src="image.imgUrl"
            />
          </van-swipe-item>
          <template #indicator>
            <div class="custom-indicator">
              <div
                :class="[
                  'indicator',
                  index === current ? 'check-indicator' : '',
                ]"
                v-for="(item, index) of images"
                :key="index"
              ></div>
            </div>
          </template>
        </van-swipe>
      </div>
    </div>
    <div class="title">
      <div class="title-name">雷达</div>
      <div class="title-switch">
        <span class="text">仅看客户</span>
        <van-switch class="marketing-switch" v-model="checked" @change="onRefreshAll" />
      </div>
    </div>
    <div class="radar-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="radarList.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="100"
        >
          <div class="today-list">
            <div class="title-name">今日</div>
            <market-card
              :info="item"
              v-for="(item, index) of todayList"
              :key="index"
            />
          </div>
          <div class="history-list">
            <div class="title-name">历史</div>
            <market-card
              :info="item"
              v-for="(item, index) of historyList"
              :key="index"
            />
          </div>
        </van-list>
        <wxb-no-data v-else />
      </van-pull-refresh>
    </div>
    <h5-navigater ref="navigater" />
  </div>
</template>

<script>
import Vue from 'vue';
import marketing from '@/api/marketing';
import { Lazyload } from 'vant';
import marketCard from './components/marketCard';
import dayjs from 'dayjs';

Vue.use(Lazyload);
export default {
  name: 'marketing',
  components: { marketCard },
  data() {
    return {
      current: 0,
      images: [],
      checked: false,
      pageNation: {
        pageNo: 0,
        pageSize: 20,
        totalRecords: 0,
      },
      loading: false,
      finished: false,
      refreshing: false,
      radarList: [],
      todayList: [],
      historyList: [],
    };
  },
  methods: {
    onRefreshAll() {
      this.radarList = [];
      this.todayList = [];
      this.historyList = [];
      this.onRefresh();
    },
    toUrl(item) {
      location.href = item.bannerUrl;
    },
    onChange(index) {
      this.current = index;
    },
    //下拉刷新
    onRefresh() {
      this.pageNation.pageNo = 0;
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.radarList = [];
        this.todayList = [];
        this.historyList = [];
        this.refreshing = false;
      }
      this.pageNation.pageNo += 1;
      this.getRadarPage();
    },
    async getRadarPage() {
      let params = {
        estate_id: localStorage.getItem('platform_estateId'),
        page_no: this.pageNation.pageNo,
        page_size: this.pageNation.pageSize,
        is_customer: this.checked,
        op_id: localStorage.getItem('platform_opId'),
      };
      let { code, data } = await marketing.getRadarPage(
        params,
        JSON.parse(localStorage.getItem('platform_role')).saleUserId
      );
      this.loading = false;
      if (code === '0') {
        this.radarList = this.radarList.concat(data.data);
        this.pageNation.totalRecords = data.total_records;
        if (this.radarList.length >= this.pageNation.totalRecords) {
          this.finished = true;
        }
        let today = this.radarList.filter((item) => {
          const date1 = new Date(dayjs(item.visit_time).format('YYYY-MM-DD'));
          const date2 = new Date(dayjs(new Date()).format('YYYY-MM-DD'));
          if ((date2 - date1) / (1000 * 3600 * 24) < 1) {
            return item;
          }
        });
        let history = this.radarList.filter((item) => {
          const date1 = new Date(dayjs(item.visit_time).format('YYYY-MM-DD'));
          const date2 = new Date(dayjs(new Date()).format('YYYY-MM-DD'));
          if ((date2 - date1) / (1000 * 3600 * 24) >= 1) {
            return item;
          }
        });
        this.todayList = today;
        this.historyList = history;
        //对数组进行时间排序
        this.todayList.sort((a, b)=>{
          let aTime=new Date(a.visit_time);
          let bTime=new Date(b.visit_time);
          return aTime > bTime ? -1 : 1;
        });
        this.historyList.sort((a, b)=>{
          let aTime=new Date(a.visit_time);
          let bTime=new Date(b.visit_time);
          return aTime > bTime ? -1 : 1;
        });
        console.log(this.todayList.length, this.historyList.length, '数组长度');
      } else {
        this.finished = true;
      }
    },

    async getBanner() {
      let { code, data } = await marketing.getBanner({ type: 2 });
      if (code === '0') {
        this.images = data;
      }
    },
  },
  mounted() {
    this.getBanner();
    this.onRefresh();
  },
};
</script>

<style lang="less" scoped>
.marketing {
  .top {
    position: relative;
    height: 166px;
    background: #ffffff;

    .top-bg {
      height: 88px;
      background: linear-gradient(
        360deg,
        rgba(57, 116, 198, 0) 0%,
        rgba(57, 116, 198, 0.02) 9%,
        rgba(57, 116, 198, 0.05) 17%,
        rgba(57, 116, 198, 0.12) 24%,
        rgba(57, 116, 198, 0.2) 31%,
        rgba(57, 116, 198, 0.29) 37%,
        rgba(57, 116, 198, 0.39) 44%,
        rgba(57, 116, 198, 0.5) 50%,
        rgba(57, 116, 198, 0.61) 56%,
        rgba(57, 116, 198, 0.71) 63%,
        rgba(57, 116, 198, 0.8) 69%,
        rgba(57, 116, 198, 0.88) 76%,
        rgba(57, 116, 198, 0.95) 83%,
        rgba(57, 116, 198, 0.98) 91%,
        #3974c6 100%
      );
    }
    .my-swipe-box {
      position: absolute;
      top: 12px;
      left: 16px;
      width: 343px;
      height: 142px;
      border-radius: 5px;
      overflow: hidden;
      transform: translateY(0);
      .top-swipe {
        .image-heard {
          width: 343px;
          height: 142px;
          border-radius: 5px;
        }

        .custom-indicator {
          position: absolute;
          right: 16px;
          bottom: 8px;
          display: flex;
          align-items: center;

          .indicator {
            width: 10px;
            height: 2px;
            background: rgba(48, 49, 51, 0.4);
            border-radius: 1px;
            margin-left: 4px;
          }

          .check-indicator {
            background: #ffffff;
          }
        }
      }
    }
  }

  .title {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebeef5;
    background: #ffffff;
    .marketing-switch{
      font-size: 15px;
    }
    .title-name {
      font-size: 17px;
      font-weight: bold;
      color: @gary-wxb-black-1;
      line-height: 24px;
    }

    .title-switch {
      display: flex;
      align-items: center;

      .text {
        font-size: 12px;
        color: @gary-wxb-gray-2;
        line-height: 17px;
        margin-right: 8px;
      }
    }
  }

  .radar-list {
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 80px
    );
    padding: 16px 16px calc(89px + env(safe-area-inset-bottom)) 16px;

    .title-name {
      font-size: 14px;
      font-weight: bold;
      color: @gary-wxb-black-1;
      line-height: 20px;
      margin-bottom: 8px;
    }

    .history-list {
      margin-top: 40px;
    }
  }
}
</style>
