<template>
  <div class="market-card" @click="jumpCard(info.union_id)">
    <div :class="['tags', info.identity != '潜客' ? 'other-tags' : '']">
      {{ info.identity }}
    </div>
    <div class="info">
      <img class="heard-img" :src="info.avatar" alt=""/>
      <div class="info-content">
        <div class="name-icon">
          <span class="name">{{
              info.identity === "潜客" ? info.nick_name : info.customer_name
            }}</span>
          <span class="main-name" v-if="info.identity === '联名客户'"
          >（{{ info.main_customer_name }}）</span
          >
          <svg v-if="info.has_wecome" class="icon" aria-hidden="true">
            <use xlink:href="#iconqiyeweixin"></use>
          </svg>
        </div>
        <div class="action">{{ info.content }}</div>
      </div>
    </div>
    <div class="time">
      <div>{{ info.visit_time | filterTime }}</div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import marketing from '@/api/marketing';

export default {
  name: 'marketCard',
  props: {
    info: {
      type: Object,
      default: () => null,
    },
  },
  filters: {
    filterTime(val) {
      if (!val) return;
      return dayjs(val).format('YYYY-MM-DD HH:mm');
    },
  },
  methods: {
    async jumpCard(unionId) {
      if (!unionId) return;
      let jumpHost = `${window.location.protocol}//` + location.host;
      const params = {
        opId: localStorage.getItem('platform_opId'),
        unionId,
        estateId: localStorage.getItem('platform_estateId')
      };
      let {code, data} = await marketing.getPeronInfo(params);
      if (code === '0') {
        if (data.identityType === 0 && data.clueId) {
          window.location.href = jumpHost + `/potential/#/clue-detail?clueId=${data.clueId}`;
        } else if (data.identityType === 1 && data.oppId) {
          window.location.href = jumpHost + `/crm/#/client/clientDetail?oppId=${data.oppId}&type=1`;
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.market-card {
  background: #ffffff;
  border-radius: 4px;
  padding: 16px 0px 12px 11px;
  position: relative;
  margin-bottom: 12px;

  .tags {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 1px 4px;
    background: #c0c4cc;
    border-radius: 0px 4px 0px 4px;
    font-size: 10px;
    color: #ffffff;
    line-height: 16px;
  }

  .other-tags {
    background-color: #18d0cc;
  }

  .info {
    border-bottom: 1px solid #f6f6f6;
    padding-bottom: 12px;
    display: flex;
    align-items: center;

    .heard-img {
      width: 48px;
      height: 48px;
      border-radius: 8px;
      border: 1px solid #ebeef5;
      margin-left: 5px;
    }

    .info-content {
      margin-left: 12px;

      .name-icon {
        display: flex;
        align-items: center;

        .name {
          font-size: 15px;
          font-weight: bold;
          color: @gary-wxb-black-1;
          line-height: 21px;
        }

        .main-name {
          font-size: 12px;
          color: @gary-wxb-gray-3;
          line-height: 21px;
        }

        .icon {
          margin-left: 8px;
          width: 16px;
          height: 16px;
        }
      }

      .action {
        width: 251px;
        font-size: 12px;
        color: @gary-wxb-gray-2;
        line-height: 17px;
        margin-top: 4px;
      }
    }
  }

  .time {
    margin-top: 12px;
    display: flex;
    align-items: center;

    div {
      font-size: 12px;
      color: @gary-wxb-gray-3;
      line-height: 17px;

      &:last-child {
        margin-left: 4px;
        font-weight: bold;
      }
    }
  }
}
</style>
