import request from '../../util/request';
import { DMP, CLUE } from '../apiConst';
export default {
  //获取雷达分页
  getRadarPage(params, sale_id) {
    return request.get({ url: `${DMP}/consultnat/customer/radar?sale_id=${sale_id}`, params });
  },
  //获取banner图
  getBanner(params) {
    return request.get({ url: `${CLUE}/market/banner/v1/get`, params });
  },
  //获取身份信息
  getPeronInfo(params) {
    return request.get({ url: `${CLUE}/clue/v1/clue-identity`, params });
  }
};
